@import '@teamleader/ahoy/es/index.css';

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
img,
strong,
sub,
sup,
ol,
ul,
li,
fieldset,
form,
label,
legend {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

body {
  font-family: var(--font-family-inter);
  font-size: calc(1.4 * var(--unit));
  line-height: calc(2.1 * var(--unit));
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}
